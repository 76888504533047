<template>
	<canvas id="canvas-map" width="800" height="800"></canvas>
</template>

<script>
	import { ref, watch, onMounted } from 'vue';
	import composableStore from '@/composables/store';
	import _ from 'lodash';
	import { fabric } from 'fabric';

	export default {
		emits: ['select'],
		props: {
			map: Object,
			orderGroups: Array
		},
		setup(props, { emit }) {
			const { codes } = composableStore();
			const canvas = ref(null);

			const loadMap = () => {
				if(canvas.value) {
					canvas.value.dispose();
					canvas.value = null;
				}

				canvas.value = new fabric.Canvas(`canvas-map`, {
					backgroundColor: '#f8f8f8',
					hoverCursor: 'pointer',
					selection: false
				});

				canvas.value.loadFromJSON(props.map.draw, () => {
					if(canvas.value instanceof fabric.Canvas) {
						canvas.value.getObjects().map((o) => {
							o.selectable = false;
						});

						render();
					}else{
						console.error('No se encontró una instancia válida de fabric.Canvas');
					}
				});

				canvas.value.on('mouse:down', (options) => {
					if(options?.target?.code_id) {
						var code = _.find(codes.value, { id: Number(options.target.code_id) });
						if(code) emit('select', code);
					}
				});
			}

			const render = () => {
				canvas.value.getObjects().map(o => {
					if(o?.code_id) {
						var orderGroup = _.find(props.orderGroups, (data) => { return (data.code.id == Number(o.code_id) && _.indexOf(['OPENED', 'PAYING'], data.status) !== -1); });

						if(o.getObjects && typeof o.getObjects === 'function') {
							o.getObjects().forEach((obj) => {
								if(obj.type != 'text') {
									var color = (orderGroup?.waiting_count > 0) ? '#ff3366' : ((orderGroup?.status == 'PAYING') ? '#00d4ff' : '#39cc5b'),
										width = (orderGroup) ? 3 : 0;

									obj.set('stroke', color);
									obj.set('strokeWidth', width);
								}
							});
						}else{
							var color = (orderGroup?.waiting_count > 0) ? '#ff3366' : ((orderGroup?.status == 'PAYING') ? '#00d4ff' : '#39cc5b'),
								width = (orderGroup) ? 3 : 0;

							o.set('stroke', color);
							o.set('strokeWidth', width);
						}
					}
				});

				canvas.value.renderAll();
			}

			onMounted(loadMap);
			watch(() => props.map, loadMap);
			watch(() => props.orderGroups, render);
		}
	}
</script>

<style>
	.canvas-container {
		margin-left: auto;
		margin-right: auto;
		width: 100% !important;
		max-width: 800px !important;
		height: unset !important;
		aspect-ratio: 1 / 1;
	}

	.lower-canvas, .upper-canvas {
		width: 100% !important;
		height: 100% !important;
		max-height: 800px !important;
	}
</style>